import React from "react"
// import SEO from "../components/seo"
import FeaturedPosts from "../components/FeaturedPosts/featuredposts"
import LatestPosts from "../components/LatestPosts/latestPosts";
import FeaturedReviews from "../components/FeaturedReviews/featuredReviews";

const Home = () => (
    <>

    </>
)

export default Home
